<script lang="ts">
import {
  Company,
  getCompanySubscriptionInfo,
  getCustomers,
  getOrganizationInfo,
} from '@/api/assetsMgmt';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { disabledPastDate } from '@/utils/date';
import { COMPANY_TYPE, TIME_UNITS } from '@/utils/workData/lookuptable';
import Treeselect from '@riophae/vue-treeselect';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'AssignForm',
  components: {
    CusFormItem,
    Treeselect,
  },
})
export default class extends Vue {
  @Prop({ required: false }) productModelInfo!: any;
  @Prop({ required: false }) state!: any;
  @Prop({ default: '' }) chosenAssetType!: string;

  /** Local variables */
  durationUnitList: any = TIME_UNITS;
  disabledAssign: boolean =
    false; /** determine whether user can go next step */
  customerList: Company[] = [];
  organList = [];
  errPartNameInfo = '';
  orderIdInfo = '';
  assignAssetForm: any = {
    orderId: '',
    cadId: '',
    customerId: '',
    organizationId: '',
    subscriptionStartDate: '',
  };
  customerInfo: any = {};
  organizationInfo: any = null;
  customerSubscriptionPackage = '';
  assetSubscriptionPackage = '';
  subscriptionEndDate = '';
  subscriptionDurationYear = 0;
  subscriptionDurationUnit = 'years';
  customerSelectLoading: boolean = false;
  pickerOptions: any = {
    disabledDate: disabledPastDate,
  };

  mounted() {
    this.$nextTick(() => {
      this.fetchCustomerList(1, 10000);
    });
  }

  @Watch('disabledAssign')
  private watchDisabledAssignChange(value: any, oldVal: any) {
    this.$emit('send-workflow', value);
  }

  /**
   * Get asset type
   */
  get assetTypeSame() {
    return this.customerInfo.supportedAssetTypes.includes(this.chosenAssetType);
  }

  /**
   * Get customer list data
   * @param page
   * @param pageSize
   */
  async fetchCustomerList(page: number, pageSize: number): Promise<void> {
    try {
      this.customerSelectLoading = true;
      const res = await getCustomers(page, pageSize);
      if (res.code === 200) {
        this.customerList = res.data.companies.filter(
          (company) => company.companyType === COMPANY_TYPE.Customer
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.customerSelectLoading = false;
    }
  }

  /**
   * Change customer info
   */
  changeCustomerInfo(): void {
    this.assignAssetForm.customerId = this.customerInfo.id;
    this.fetchOrganizationList();
    this.organizationInfo = null;
    this.assignAssetForm.organizationId = '';
    this.subscriptionDurationYear = 0;
  }

  /**
   * Change organization info
   */
  changeOrganizationInfo(): void {
    if (!this.organizationInfo) {
      return;
    }
    this.assignAssetForm.organizationId = this.organizationInfo.id;
    this.fetchSubscriptionInfo();
  }

  /**
   * Change date time picker value
   */
  changeDatePicker(): void {
    let date = new Date();
    let time = moment(date).format('HH:mm:ss');
    this.assignAssetForm.subscriptionStartDate = `${this.assignAssetForm.subscriptionStartDate}T${time}`;
    if (
      this.assignAssetForm.subscriptionStartDate &&
      this.subscriptionDurationUnit
    ) {
      const unit: moment.unitOfTime.DurationConstructor =
        this.subscriptionDurationUnit == 'years'
          ? 'years'
          : this.subscriptionDurationUnit == 'months'
          ? 'months'
          : 'days';
      this.subscriptionEndDate = moment(
        this.assignAssetForm.subscriptionStartDate
      )
        .add(Number(this.subscriptionDurationYear), unit)
        .format('YYYY-MM-DD HH:mm:ss');
    } else {
      this.subscriptionEndDate = '';
    }
  }

  /**
   * Get organizations list by customer id
   */
  async fetchOrganizationList(): Promise<void> {
    // let id: number | string = this.customerInfo.id ? this.customerInfo.id : -1;
    const res = await getOrganizationInfo(this.customerInfo.id);
    if (res.code === 200) {
      this.organList = res.data;
      if (res.data.length === 0) {
        this.$emit('send-workflow', false);
        this.disabledAssign = false;
      } else {
        this.$emit('send-workflow', false);
        this.disabledAssign = false;
      }
    }
  }

  /**
   * Get company subscription info by customer id and asset type
   */
  async fetchSubscriptionInfo(): Promise<void> {
    try {
      const res = await getCompanySubscriptionInfo(this.customerInfo.id);
      if (res.code === 200) {
        if (res.data.length > 0) {
          /** Get subscription info about customer */
          this.disabledAssign = false;
          let customerSubscriptionInfo: any = res.data.filter((item: any) => {
            return item.subscriptionPackageType === 'SPT_COMPANY_TYPE';
          });
          if (customerSubscriptionInfo.length > 0) {
            this.customerSubscriptionPackage =
              customerSubscriptionInfo[0].subscriptionPackageName;
          } else {
            this.customerSubscriptionPackage = '';
          }

          /** Get subscription about asset and filter asset subscription by chossen type */
          let chosenAssetSubscriptionInfoArray: any = res.data.filter(
            (item: any) => {
              return (
                item.subscriptionPackageType === 'SPT_ASSET_TYPE' &&
                item.subscriptionPackageAssetType === this.chosenAssetType
              );
            }
          );
          if (chosenAssetSubscriptionInfoArray.length > 0) {
            this.assetSubscriptionPackage =
              chosenAssetSubscriptionInfoArray[0].subscriptionPackageName;
            let assetSubscriptionInfo: any =
              chosenAssetSubscriptionInfoArray[0];
            if (assetSubscriptionInfo.systemFeatures.length > 0) {
              let durationInfo: any =
                assetSubscriptionInfo.systemFeatures.filter((item: any) => {
                  return item.code === 'SYSFEAT_DURATION';
                });
              this.subscriptionDurationYear = durationInfo[0].value
                ? durationInfo[0].value
                : 0;
              if (durationInfo[0].unit) {
                this.subscriptionDurationUnit = this.getSubscriptionUnit(
                  durationInfo[0].unit
                );
              }
            }
          } else {
            this.assetSubscriptionPackage = '';
            this.disabledAssign = true;
          }
        } else {
          this.disabledAssign = true;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Get subscription unit
   * @param unitCode
   * @returns string
   */
  getSubscriptionUnit(unitCode: string): string {
    let subscriptionUnit: string = '';
    if (unitCode === this.durationUnitList.UnitYear) {
      subscriptionUnit = 'years';
    } else if (unitCode === this.durationUnitList.UnitMonth) {
      subscriptionUnit = 'months';
    } else {
      subscriptionUnit = 'days';
    }
    return subscriptionUnit;
  }

  validateOrderIdAndCadId = (rule: any, value: any, callback: any) => {
    let newReplaceValue = value.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    if (newReplaceValue.length < 1 || newReplaceValue.length > 50) {
      callback(new Error(`${this.$t('assetMgmt.tipInputLengthIncorrect')}`));
    } else {
      callback();
    }
  };

  /**
   * Form rules validation
   */
  get rules() {
    const tmpRules = {
      orderId: [
        {
          required: true,
          message: this.$t('assetMgmt.tipInputOrderId'),
          trigger: 'change',
        },
        { validator: this.validateOrderIdAndCadId, trigger: 'change' },
      ],
      cadId: [
        {
          required: true,
          message: this.$t('assetMgmt.tipInputCadId'),
          trigger: 'change',
        },
        { validator: this.validateOrderIdAndCadId, trigger: 'change' },
      ],
      customerId: [
        {
          required: true,
          message: this.$t('assetMgmt.tipSelectCustomer'),
          trigger: 'change',
        },
      ],
      organizationId: [
        {
          required: true,
          message: this.$t('assetMgmt.tipSelectOrg'),
          trigger: 'change',
        },
      ],
      subscriptionStartDate: [
        {
          required: true,
          message: this.$t('assetMgmt.selectSubscriptionStartDate'),
          trigger: 'change',
        },
      ],
    };

    return tmpRules;
  }

  /**
   * Send form data event to the parent
   */
  sendFormData(): void {
    (this.$refs.assignAssetForm as any).validate(async (valid: any) => {
      if (valid) {
        this.$emit('change-nav-state');
        let sendData = {
          orderId: this.assignAssetForm.orderId,
          cadId: this.assignAssetForm.cadId,
          customerId: this.customerInfo.id,
          customerName: this.customerInfo.name,
          organizationName: this.organizationInfo.name,
          organizationId: this.organizationInfo.id,
          subscriptionPackage: this.customerSubscriptionPackage,
          subscriptionStartDate: this.assignAssetForm.subscriptionStartDate,
          subscriptionEndDate: this.subscriptionEndDate,
          assetType: this.chosenAssetType,
        };
        this.$emit('send-assign-form', sendData);
      }
    });
  }

  /**
   * Handle custom key for tree selection
   * @param node
   */
  customKey(node: any): { id: string; label: string; children: any } {
    return {
      id: node.id,
      label: node.name,
      children: node.children.length > 0 ? node.children : undefined,
    };
  }
}
</script>

<template>
  <div>
    <p class="title">{{ $t('assetMgmt.orderInfo') }}</p>
    <el-form
      ref="assignAssetForm"
      :model="assignAssetForm"
      :rules="rules"
      style="margin: 5px 20px 60px"
    >
      <el-row style="min-width: 1100px; margin-left: -50px">
        <el-col :span="8">
          <CusFormItem :title="'assetMgmt.orderId'" :errContent="orderIdInfo">
            <el-form-item prop="orderId">
              <el-input
                v-model="assignAssetForm.orderId"
                size="medium"
                v-bind:placeholder="$t('assetMgmt.inputOrderId')"
              />
            </el-form-item>
          </CusFormItem>

          <CusFormItem :title="'assetMgmt.cadId'" :errContent="errPartNameInfo">
            <el-form-item prop="cadId">
              <el-input
                v-model="assignAssetForm.cadId"
                size="medium"
                v-bind:placeholder="$t('assetMgmt.inputCadId')"
              />
            </el-form-item>
          </CusFormItem>

          <CusFormItem :title="'assetMgmt.customer'">
            <el-form-item prop="customerId">
              <el-select
                v-model="customerInfo"
                filterable
                v-bind:placeholder="$t('assetMgmt.selecCustomer')"
                @change="changeCustomerInfo"
                value-key="name"
                v-loading="customerSelectLoading"
                style="width: 320px"
              >
                <el-option
                  v-for="(item, index) in customerList"
                  :key="index"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </CusFormItem>

          <CusFormItem :title="'assetMgmt.organization'">
            <el-form-item prop="organizationId">
              <treeselect
                :options="organList"
                v-model="organizationInfo"
                valueFormat="object"
                :normalizer="customKey"
                :placeholder="$t('assetMgmt.selectOrganization')"
                :disabled="!customerInfo.name"
                @input="changeOrganizationInfo"
                style="width: 320px"
              />
            </el-form-item>
          </CusFormItem>
        </el-col>

        <el-col :span="10" :offset="4">
          <CusFormItem
            :title="'assetMgmt.customerSubscriptionPackage'"
            :required="false"
            class="long_item"
          >
            <el-form-item>
              <span class="text_display">{{
                customerSubscriptionPackage
              }}</span>
            </el-form-item>
          </CusFormItem>

          <CusFormItem
            :title="'assetMgmt.subscriptionStartDate'"
            class="long_item"
          >
            <el-form-item prop="subscriptionStartDate">
              <el-date-picker
                v-model="assignAssetForm.subscriptionStartDate"
                type="date"
                v-bind:placeholder="$t('assetMgmt.selectSubscriptionStartDate')"
                :picker-options="pickerOptions"
                @change="changeDatePicker"
                value-format="yyyy-MM-dd"
                style="width: 320px"
              />
            </el-form-item>
          </CusFormItem>

          <CusFormItem
            :title="'assetMgmt.subscriptionEndDate'"
            :required="false"
            class="long_item"
          >
            <el-form-item>
              <span class="text_display">{{ subscriptionEndDate }}</span>
            </el-form-item>
          </CusFormItem>

          <CusFormItem
            :title="'assetMgmt.assetSubscriptionPackage'"
            :required="false"
            class="long_item"
          >
            <el-form-item prop="assetSubscriptionPackage">
              <!-- <span class="text_display">{{$t(chosenAssetType)}}</span> -->
              <span class="text_display">{{
                $t(assetSubscriptionPackage)
              }}</span>
            </el-form-item>
          </CusFormItem>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
$FormWidth: 320px;
$FontSize: 1.04em;

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #373e41;
  margin: 26px 20px 0px;
}
:deep(.el-input--medium .el-input__inner) {
  width: $FormWidth;
  font-size: $FontSize;
}
.long_item :deep(.form-title) {
  width: 260px;
  height: 40px;
  line-height: 40px;
}
.text_display {
  font-size: $FontSize;
  font-family: $font-Roboto-Regular;
}
::deep(.el-date-editor.el-input, .el-date-editor.el-input__inner) {
  width: auto;
}
</style>
