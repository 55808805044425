import { FilterOperator } from '@/model/queryParameters/QueryParameter';

export const ASSETS_COLS = [
  {
    label: 'assetsModule.assetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetsModule.VIN',
    prop: 'vinNumber',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetsModule.controllerId',
    prop: 'controllerId',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetsModule.lastCommunicationTime',
    prop: 'lastCommunicationTime',
    required: true,
    visible: true,
    dateFormat: 'datetime',
    sortable: 'custom',
  },
  {
    label: 'assetsModule.activityStatus',
    prop: 'status',
    required: true,
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'organizationModule.organizationName',
    prop: 'organization',
    required: false,
    visible: true,
    sortable: 'custom',
  },
];

export const REGISTERED_ASSETS_COLS = [
  {
    label: 'assetMgmt.gatewayId',
    prop: 'gatewayId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.preliminaryAssetId',
    prop: 'preliminaryAssetId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.AssetHIN',
    prop: 'assetDataCollectionId',
    required: true,
    visible: true,
    sortable: true,
    width: '240',
  },
  {
    label: 'assetMgmt.VIN',
    prop: 'vinNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.assetSerialNo',
    prop: 'serialNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.prodModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.modelNumber',
    prop: 'productModelNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.assetType',
    prop: 'translatedAssetType',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.timeZone',
    prop: 'timezone',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.controllerId',
    prop: 'controllerId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.status',
    prop: 'translatedConnectionStatus',
    required: true,
    visible: true,
    sortable: true,
    multiLang: true,
  },
  {
    label: 'assetMgmt.manufacterDate',
    prop: 'manufactureDate',
    dateFormat: 'date',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.registeringOwner',
    prop: 'registrationHandlerCompanyName',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'common.serviceRegion',
    prop: 'translatedServiceRegion',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'common.hyvaRegion',
    prop: 'translatedHyvaRegion',
    required: true,
    visible: true,
    sortable: 'custom',
  },
];

export const CONNECTED_ASSETS_COLS = [
  {
    label: 'assetMgmt.gatewayId',
    prop: 'gatewayId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.preliminaryAssetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.VIN',
    prop: 'vinNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.assetSerialNumber',
    prop: 'serialNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.prodModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.modelNumber',
    prop: 'productModelNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.assetType',
    prop: 'translatedAssetType',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.timeZone',
    prop: 'timezone',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.lastCommutTime',
    prop: 'lastCommunicationTime',
    required: true,
    visible: true,
    dateFormat: 'datetime',
    sortable: true,
    width: '230',
  },
  {
    label: 'assetMgmt.installTime',
    prop: 'installTime',
    required: true,
    visible: true,
    dateFormat: 'datetime',
    sortable: true,
  },
  {
    label: 'assetMgmt.controllerId',
    prop: 'controllerId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.assetDataColc',
    prop: 'assetDataCollectionId',
    required: true,
    visible: true,
    sortable: true,
    width: '230',
  },
  {
    label: 'assetMgmt.manufactureDate',
    prop: 'manufactureDate',
    required: true,
    visible: true,
    sortable: true,
    dateFormat: 'date',
    width: '230',
  },
  {
    label: 'assetMgmt.connectingOwner',
    prop: 'connectionHandlerCompanyName',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'common.serviceRegion',
    prop: 'translatedServiceRegion',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'common.hyvaRegion',
    prop: 'translatedHyvaRegion',
    required: true,
    visible: true,
    sortable: 'custom',
  },
];

export const ASSIGNED_ASSETS_COLS = [
  {
    label: 'assetMgmt.assetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.VIN',
    prop: 'vinNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.serialNumber',
    prop: 'serialNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.assetType',
    prop: 'translatedAssetType',
    required: false,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.prodModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.modelNumber',
    prop: 'productModelNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.customer',
    prop: 'companyName',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.organization',
    prop: 'organizationName',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.timeZone',
    prop: 'timezone',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.lastCommutTime',
    prop: 'lastCommunicationTime',
    required: true,
    visible: true,
    dateFormat: 'datetime',
    sortable: true,
    width: '230',
  },
  {
    label: 'assetMgmt.installTime',
    prop: 'installTime',
    required: true,
    visible: true,
    dateFormat: 'datetime',
    sortable: true,
  },
  {
    label: 'assetMgmt.controllerId',
    prop: 'controllerId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.gatewayId',
    prop: 'gatewayId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.assetDataCollectionId',
    prop: 'assetDataCollectionId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.manufactureDate',
    prop: 'manufactureDate',
    required: true,
    visible: true,
    sortable: true,
    dateFormat: 'date',
  },
  {
    label: 'common.serviceRegion',
    prop: 'translatedServiceRegion',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'common.hyvaRegion',
    prop: 'translatedHyvaRegion',
    required: true,
    visible: true,
    sortable: 'custom',
  },
];

export const CONNECTED_ASSETS_SEARCH_OPTION_COLS = [
  {
    label: 'assetMgmt.preliminaryAssetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.VIN',
    prop: 'vinNumber',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.installTime',
    prop: 'installTime',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.assetType',
    prop: 'assetType',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.productModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: true,
  },
  // {
  //   label: 'common.serviceRegion',
  //   prop: 'serviceRegion',
  //   required: true,
  //   visible: true,
  //   sortable: true,
  // },
  // {
  //   label: 'common.hyvaRegion',
  //   prop: 'hyvaRegion',
  //   required: true,
  //   visible: true,
  //   sortable: true,
  // },
];

export const ASSIGNED_ASSETS_SEARCH_OPTION_COLS = [
  {
    label: 'assetMgmt.preliminaryAssetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.VIN',
    prop: 'vinNumber',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.assetType',
    prop: 'assetType',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.EQUAL,
  },
  {
    label: 'assetMgmt.productModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.company',
    prop: 'companyName',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  // {
  //   label: 'common.serviceRegion',
  //   prop: 'serviceRegion',
  //   required: true,
  //   visible: true,
  //   sortable: true,
  // },
  // {
  //   label: 'common.hyvaRegion',
  //   prop: 'hyvaRegion',
  //   required: true,
  //   visible: true,
  //   sortable: true,
  // },
];

export const ASSETS_SEARCH_OPTION_COLS = [
  {
    label: 'assetMgmt.cusAssetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.assetType',
    prop: 'assetType',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.EQUAL,
  },
  {
    label: 'assetMgmt.productModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
];

export const REGISTERED_ASSETS_SEARCH_OPTIONS = [
  {
    label: 'assetMgmt.assetSerialNumber',
    prop: 'assetSerialNumber',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.preliminaryAssetId',
    prop: 'preliminaryAssetId',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.VIN',
    prop: 'vinNumber',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.gatewayId',
    prop: 'gatewayId',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.assetType',
    prop: 'assetType',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.EQUAL,
  },
  {
    label: 'assetMgmt.productModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.status',
    prop: 'connectStatus',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.EQUAL,
  },
  // {
  //   label: 'common.serviceRegion',
  //   prop: 'serviceRegion',
  //   required: true,
  //   visible: true,
  //   sortable: true,
  // },
  // {
  //   label: 'common.hyvaRegion',
  //   prop: 'hyvaRegion',
  //   required: true,
  //   visible: true,
  //   sortable: true,
  // },
];

export const UNASSIGNED_ASSETS_SEARCH_OPTIONS = [
  {
    label: 'assetMgmt.gatewayId',
    prop: 'gatewayId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.cusAssetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.assetDataColc',
    prop: 'assetDataCollectionId',
    required: true,
    visible: true,
    sortable: true,
    width: '230',
  },
  {
    label: 'assetMgmt.assetSerialNo',
    prop: 'serialNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.prodModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.modelNumber',
    prop: 'productModelNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.assetType',
    prop: 'assetType',
    required: false,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'assetMgmt.manufacterDate',
    prop: 'manufactureDate',
    required: true,
    visible: true,
    sortable: true,
    dateFormat: 'date',
  },
  {
    label: 'assetMgmt.controllerId',
    prop: 'controllerId',
    required: true,
    visible: true,
    sortable: true,
  },
  // {
  //   label: 'common.serviceRegion',
  //   prop: 'serviceRegion',
  //   required: true,
  //   visible: true,
  //   sortable: true,
  // },
  // {
  //   label: 'common.hyvaRegion',
  //   prop: 'hyvaRegion',
  //   required: true,
  //   visible: true,
  //   sortable: true,
  // },
];

export const ADD_ACCESS_COLS = [
  {
    label: 'assetMgmt.assetDataCollectionId',
    prop: 'assetDataCollectionId',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetMgmt.serialNumber',
    prop: 'serialNumber',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'common.serviceRegion',
    prop: 'serviceRegion',
    required: true,
    visible: true,
    sortable: 'custom',
    multiLang: true,
  },
  {
    label: 'assetMgmt.customer',
    prop: 'companyName',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetMgmt.productModel',
    prop: 'productModel',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetMgmt.modelNumber',
    prop: 'productModelNumber',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetMgmt.assetType',
    prop: 'assetType',
    required: true,
    visible: true,
    sortable: 'custom',
    multiLang: true,
  },
  {
    label: 'assetMgmt.manufactureDate',
    prop: 'manufactureDate',
    required: true,
    dateFormat: 'date',
    visible: true,
    sortable: 'custom',
  },
];

export const CUSTOMER_MANAGEMENT_DESIGNATED_USER_ASSETS_COLS = [
  {
    label: 'assetMgmt.assetDataCollectionId',
    prop: 'assetDataCollectionId',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetMgmt.serialNumber',
    prop: 'serialNumber',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetMgmt.assetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'common.serviceRegion',
    prop: 'translatedServiceRegion',
    required: true,
    visible: true,
    sortable: 'custom',
    multiLang: true,
  },
  {
    label: 'assetMgmt.assetStatus',
    prop: 'translatedStatus',
    required: true,
    visible: true,
    sortable: 'custom',
    multiLang: true,
  },
  {
    label: 'assetMgmt.customer',
    prop: 'companyName',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetMgmt.organization',
    prop: 'organizationName',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetMgmt.productModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetMgmt.modelNumber',
    prop: 'productModelNumber',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetMgmt.assetType',
    prop: 'translatedAssetType',
    required: true,
    visible: true,
    sortable: 'custom',
    multiLang: true,
  },
  {
    label: 'assetMgmt.accessDateAndTime',
    prop: 'accessTime',
    required: true,
    dateFormat: 'date',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'assetMgmt.accessOrganization',
    prop: 'accessOrganizations',
    required: true,
    visible: true,
    sortable: false,
  },
  {
    label: 'assetMgmt.manufacterDate',
    prop: 'manufactureDate',
    dateFormat: 'date',
    required: true,
    visible: true,
    sortable: true,
  },
];

export const BODY_BUILDER_DEALER_REGISTERED_ASSETS_SEARCH_OPTIONS = [
  {
    label: 'assetMgmt.assetSerialNumber',
    prop: 'assetSerialNumber',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
  {
    label: 'assetMgmt.assetType',
    prop: 'assetType',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.EQUAL,
  },
  {
    label: 'assetMgmt.productModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: true,
    operator: FilterOperator.LIKE,
  },
];
