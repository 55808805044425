import { AssetType } from '@/utils/assetTypes';
import { getRequest } from '@/utils/request';
import { COMPANY_TYPE, ConnectionStatus } from '@/utils/workData/lookuptable';
import { OperationalStatus } from '@/utils/workData/operationalStatus';
import { AssetObjectModel } from './assets';
import { CommonResultPromise } from './commonResult';
import { HyvaRegionCode } from './hyvaRegion';
import { ProductModel } from './products';
import { ServiceRegionCode } from './serviceRegions';

export function getProductModels(): CommonResultPromise<ProductModel[]> {
  const request = getRequest();
  const response = request.request({
    url: '/productModels',
    method: 'get',
  });
  return response;
}

export function getProductModelsByAseetType(
  assetType: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/productModels?assetType=${assetType}`,
    method: 'get',
  });
  return response;
}

export function getProductModelsByCustomParams(
  customParams: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/productModels${customParams}`,
    method: 'get',
  });
  return response;
}

export interface AssetFiltered {
  id: string;
  companyId?: string;
  companyName?: string;
  controllerId: string;
  assetId?: string;
  companyAssetId: string;
  connectionHandlerCompanyId?: string;
  connectionHandlerCompanyName?: string;
  assetDataCollectionId: string;
  assetOnBoardingId?: string;
  lastCommunicationTime?: string;
  status?: OperationalStatus;
  assetType: AssetType;
  assignDate?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
  gatewayId: string;
  manufactureDate: string;
  serialNumber: string;
  organizationName?: string;
  warrantyStatus?: string;
  productModelId?: string;
  productModelCode: string;
  productModelName?: string;
  productModelNumber: string;
  connectStatus: ConnectionStatus;
  installTime: string;
  timezone?: string;
  organizationId?: string;
  vinNumber: string;
  preliminaryAssetId: string;
  serviceRegion: ServiceRegionCode;
  hyvaRegion: HyvaRegionCode;
}

export interface AssetList {
  assets: AssetFiltered[];
  total: number;
}

export function getUnassignAssetsByCustomUrl(
  customUrl: string
): CommonResultPromise<AssetList> {
  const request = getRequest();
  const response = request.get(`/unassignedAssets${customUrl}`);
  return response;
}

export function getCustomers(
  page: number = 0,
  size: number = 10
): CommonResultPromise<CompanyResponse> {
  const request = getRequest();
  const response = request.get<CompanyResponse>(
    `/companies?page=${page}&size=${size}`
  );
  return response;
}

export function getOrganizationInfo(companyId: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/organizations?companyId=${companyId}`,
    method: 'get',
  });
  return response;
}

export function getAssetInfoById(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/assets/${id}`,
    method: 'get',
  });
  return response;
}

export function getCompanySubscriptionInfo(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companySubscription/${id}`,
    method: 'get',
  });
  return response;
}

export function postAssignAssets(
  customerId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/assignAssets/${customerId}`,
    method: 'post',
    data,
  });
  return response;
}

/* ======================= ASSET ON BOARDING APIs ==================================== */
export function getAssetOnBoardingByFilterParam(
  requestUrlParams: string
): CommonResultPromise<AssetOnboardingResponse> {
  const request = getRequest();
  const response = request.request<AssetOnboardingResponse>({
    url: `assetOnBoarding${requestUrlParams}`,
    method: 'get',
  });
  return response;
}

export function getAssetOnBoardingById(assetId: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/assetOnBoarding/${assetId}`,
    method: 'get',
  });
  return response;
}

export function getUnassignedGateways(): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/unassignedGateways`,
    method: 'get',
  });
  return response;
}

export function postConnectAssetOnBoarding(
  assetId: string,
  payloadData: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/connectAssetOnBoarding/${assetId}`,
    method: 'post',
    data: payloadData,
  });
  return response;
}

export function editRegisterAsset(
  assetId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/assetOnBoarding/${assetId}`,
    method: 'put',
    data: data,
  });
  return response;
}

interface PagedAssetsResponse {
  assets: AssetObjectModel[];
  size: number;
  total: number;
}

export function getConnectedAssets(
  url: string
): CommonResultPromise<PagedAssetsResponse> {
  const request = getRequest();
  const response = request.request({
    url: url,
    method: 'get',
  });
  return response;
}

export function getAssignedAssets(
  url: string
): CommonResultPromise<PagedAssetsResponse> {
  const request = getRequest();
  const response = request.request({
    url: url,
    method: 'get',
  });
  return response;
}

export function getAssetOnBoardingProgressById(
  assetOnboardingId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/assetOnBoardingProgress/${assetOnboardingId}`,
    method: 'get',
  });
  return response;
}

export interface AssetOnboardingResponse {
  size: number;
  total: number;
  assetsOnBoarding: AssetOnboarding[];
}

export interface AssetOnboarding {
  id: string;
  assetDataCollectionId: string;
  assetType: string;
  productModelCode: string;
  productModelNumber: string;
  serialNumber: string;
  serviceRegion: ServiceRegionCode;
  hyvaRegion: HyvaRegionCode;
  controllerId: string;
  connectionStatus: ConnectionStatus;
  connectStatus: ConnectionStatus;
  timezone: string;
  registrationHandlerCompanyId: string;
  registrationHandlerCompanyName: string;
  registrationHandlerOrganizationId: string;
  registrationHandlerOrganizationName: string;
  registrationHandlerUserId: string;
  registrationHandlerUserName: string;
}

export interface CompanyResponse {
  companies: Company[];
  count: number;
  total: number;
  links: CompanyLink;
}

export interface Company {
  id: string;
  activationStatus: string;
  assetCount: number;
  city: string;
  companyType: COMPANY_TYPE;
  contactPersonEmail: string;
  contactPersonName: string;
  contactPersonPhone: string;
  contractEndDate: string;
  contractNumber: string;
  contractStartDate: string;
  country: string;
  deleted: boolean;
  district: string;
  installerCode: string;
  name: string;
  organizationCount: number;
  primaryUserEmail: string;
  primaryUserId: string;
  province: string;
  refreshDataInterval: number;
  street: string;
  userCount: number;
  zipCode: string;
}

export interface CompanyLink {
  first: CompanyLinkHref;
  last: CompanyLinkHref;
  self: CompanyLinkHref;
}

interface CompanyLinkHref {
  href: string;
}
